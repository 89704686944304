import { DOCUMENT_STATUS } from '@tenant/modules/tenant/document-extraction/utils/constants'
import { BILL_STATUS } from '@tenant/modules/tenant/purchases-bill/utils/constants'
import { EXPENSE_STATUS } from '@tenant/modules/tenant/purchases-expense/utils/constants'
import {
    dayjsFormat,
    dayjsFormatWithTimezone,
    humanLongFormat,
} from '@tenant/utils/day'
import { CHART_OF_ACCOUNT_TYPES } from '@tenant/modules/tenant/bookeeping-accounts/utils/constants'

export default {
    general: {
        appName: 'Cybooks',
        na: 'N/A',
        unscheduled: 'Unscheduled',
        summary: 'Summary',
        compare: 'Compare',
        quantity: 'Quantity',
        restore: 'Restore',
        unitPrice: 'Unit Price',
        name: 'Name',
        due: 'Due {date}',
        days: '{number} days',
        addNewRow: 'Add new row',
        custom: 'Custom',
        actions: 'Actions',
        close: 'Close',
        create: 'Create',
        refresh: 'Refresh',
        completed: 'Completed',
        complete: 'Complete',
        review: 'Review',
        finalised: 'Finalised',
        writeSomething: 'Write something...',
        update: 'Update',
        adjust: 'Adjust',
        taxIncl: 'Tax Inclusive',
        taxExcl: 'Tax Exclusive',
        unableToDisplayPDF:
            'Unable to display <a href="{link}" class="text-primary-500">PDF</a> file.',
        month: 'Month',
        amount: 'Amount',
        address: 'Address',
        defaultCurrency: 'Default Currency',
        billingAddress: 'Billing Address',
        updateInfo: 'Update information',
        otherDetails: 'Other Details',
        paymentTermsLabel: 'Payment Terms',
        answer: 'Answer',
        invalid: 'Invalid',
        onDate: 'on {date}',
        customerNo: 'Cust No. {number}',
        createNewContactBtn: 'Create new contact',
        itemized: 'Itemized',
        itemize: 'Itemize',
        backToSingleView: 'Back to single view',
        active: 'Active',
        unsaved: 'Unsaved',
        setAsDefault: 'Set as default',
        showLess: 'Show less',
        showMore: 'Show more',
        accountant: 'Accountant',
        account: 'Account',
        taxRate: 'Tax Rate',
        selectAccount: 'Select account',
        selectTaxRate: 'Select tax rate',
        selectAccountType: 'Select account type',
        addNewAccountType: 'Add new account type',
        plan: 'Plan',
        next: 'Next',
        previous: 'Previous',
        spent: 'Spent',
        received: 'Received',
        price: 'Price',
        goToTopFolder: 'Go to top folder',
        roles: 'Roles',
        action: 'Action',
        addNew: 'Add new',
        new: 'New',
        exist: 'Exist',
        undo: 'Undo',
        role: 'Role',
        selectedText: '{count} selected',
        language: 'Language',
        signInWithGoogleButton: 'Sign in with Google',
        chooseValueLabel: 'Choose value',
        enterValueLabel: 'Type value and enter',
        continue: 'Continue',
        cancel: 'Cancel',
        connect: 'Connect',
        done: 'Done',
        cancelGoBack: 'Cancel, go back',
        confirm: 'Confirm',
        ok: 'OK',
        submit: 'Submit',
        add: 'Add',
        send: 'Send',
        resend: 'Resend',
        save: 'Save',
        applyNow: 'Apply Now',
        saveAndNew: 'Save and New',
        back: 'Back',
        empty: 'Empty',
        invite: 'Invite',
        change: 'Change',
        print: 'Print',
        download: 'Download',
        selectDate: 'Select date | Select dates',
        import: 'Import',
        filters: 'Filters',
        filter: 'Filter',
        resetFilters: 'Reset filters',
        clearSearch: 'Clear search',
        startDate: 'Start Date',
        endDate: 'End Date',
        filtersApplied: '{number} filters applied',
        title: 'Title',
        organisation: 'Organisation',
        type: 'Type',
        inProcess: 'In process...',
        currencySymbol: '€',
        dismiss: 'Dismiss',
        viewChanges: 'View changes',
        success: 'Success',
        error: 'Error',
        search: 'Search',
        delete: 'Delete',
        deleted: 'Deleted',
        edit: 'Edit',
        archive: 'Archive',
        unArchive: 'Unarchive',
        duplicate: 'Duplicate',
        currency: 'Currency',
        balance: 'Balance: {balance}',
        apply: 'Apply',
        resetAll: 'Reset all',
        insert: 'Insert ({number})',
        gross: 'Gross',
        net: 'Net',
        today: 'Today',
        daysAgo: '{day} Days ago',
        inQueue: 'In queue',
        failed: 'Failed',
        yes: 'Yes',
        no: 'No',
        remove: 'Remove',
        upload: 'Upload',
        available: 'Available',
        unavailable: 'Unavailable',
        viewAll: 'View all',
        createNewAccount: 'Create new account',
        recommended: 'Recommended',
        debit: 'Debit',
        credit: 'Credit',
        currentlyWeOnlySupport: 'Currently we support only {name}',
        dr: 'Dr',
        cr: 'Cr',
        insertText: ({ named }) => {
            let text = 'Insert'

            const name = named('name')
            const language = named('language')
            if (name && language) {
                text = `${text} - ${name} (${language})`
            }

            return text
        },
        statuses: {
            draft: 'Draft',
            unpaid: 'Unpaid',
            'part-paid': 'Partially paid',
            paid: 'Paid',
            due: 'Overdue|Overdue by 1 day|Overdue by {count} days',
            open: 'Open',
            closed: 'Closed',
            repeating: 'Repeating',
        },
        paymentTerms: {
            due_on_receipt: 'Due on receipt',
            net15: 'Net 15',
            net30: 'Net 30',
            net45: 'Net 45',
            end_of_month: 'End of month',
            due_next_month: 'Due next month',
            custom: 'Custom',
        },
        partialStatus: {
            draft: 'Draft',
            unpaid: 'Unpaid',
            'part-paid': 'Partial',
            paid: 'Paid',
            due: 'Overdue',
            sent: 'Sent',
            ready: 'Ready',
            published: 'Published',
            calculated: 'Calculated',
            accepted: 'Accepted',
            declined: 'Declined',
            invoiced: 'Invoiced',
        },
        lineItemsExchangeRate: '(As on {now}) 1 {fromIso} = {factor} {toIso}',

        coaTypes: ({ named }) => {
            const type = named('type')
            let name = ''

            switch (type) {
                case CHART_OF_ACCOUNT_TYPES.asset:
                    name = 'Assets'
                    break
                case CHART_OF_ACCOUNT_TYPES.liability:
                    name = 'Liabilities'
                    break
                case CHART_OF_ACCOUNT_TYPES.equity:
                    name = 'Equity'
                    break
                case CHART_OF_ACCOUNT_TYPES.income:
                    name = 'Income'
                    break
                case CHART_OF_ACCOUNT_TYPES.expense:
                    name = 'Expense'
                    break
                default:
                    break
            }

            return name
        },
        notFound: {
            head: 'Page not found',
            title: 'We lost this page',
            description:
                "The page you are looking for doesn't exist or has been moved.",
            goToDashboard: 'Go to the Dashboard',
            goBack: 'Go back',
        },
    },

    validation: {
        errorsOccurred: '{count} errors have occurred',
        lineItems: 'Complete the line items',
        line: 'Error on line {count}',
        required: ({ named }) => {
            const field = named('field').replace(/\*/g, '').trim()

            return `Complete the "${field}" field.`
        },
        min: ({ named }) => {
            const field = named('field').replace(/\*/g, '').trim()
            const min = named('min')

            return `"${field}" must be at least ${min} characters.`
        },
    },

    statuses: {
        done: 'Done',
        inProgress: 'In Progress',
    },

    sidebar: {
        home: {
            label: 'Dashboard',
        },
        businessSnapshot: {
            label: 'Business Snapshot',
        },
        employees: {
            label: 'Employees',
            overviewLabel: 'Overview',
            departmentsLabel: 'Departments',
            jobPositionsLabel: 'Job Positions',
            contractTypesLabel: 'Contract Types',
            payrollLabel: 'Payroll',
            leave: 'Leave',
            payEmployees: 'Pay employees',
            taxesAndFilings: 'Taxes & filings',
            payrollSettings: 'Payroll settings',
            calendarLabel: 'Calendar',
        },
        settings: {
            label: 'Settings',
            usersLabel: 'Users',
            accountsLabel: 'Accounts',
            companyLabel: 'Company',
            systemLabel: 'System',
            taxLabel: 'Tax',
        },
        contacts: {
            label: 'Contacts',
        },
        purchases: {
            label: 'Purchases',
            expenses: 'Expenses',
            bill: 'Bill',
            vendorCredits: 'Vendor Credits',
        },
        documentExtraction: {
            label: 'Document Extraction',
            inboxLabel: 'Inbox',
            inProcessingLabel: 'In Processing',
            reviewLabel: 'To Review',
            readyLabel: 'Ready',
            archivedLabel: 'Archive',
            failLabel: 'Fail',
        },
        bills: {
            label: 'Document Extraction',
            inboxLabel: 'Inbox',
            inProcessingLabel: 'In Processing',
            reviewLabel: 'To Review',
            readyLabel: 'Ready',
            archivedLabel: 'Archive',
        },
        transactions: {
            label: 'Transactions',
        },
        myCybooks: {
            taxCalendarLabel: 'Tax calendar',
        },
    },

    transactions: {
        emptyText: 'No transactions',
        emptySubText: 'No transactions found',
    },

    slideOut: {
        paymentMethod: {
            list: {
                title: 'Payment method',
                description: 'Update your plan payment details.',
                addPaymentMethod: 'Add payment method',
            },
            add: {
                title: 'New payment method',
                nameOnCard: {
                    label: 'Name on card',
                    placeholder: 'Enter name on card...',
                },
                description: 'Update your plan payment details.',
                cardNumber: 'Card number',
                expiry: 'Expiry',
                cvc: 'CVC',
                billingAddress: 'Billing address',
                cardEndingIn:
                    '<span class="capitalize">{brand}</span> ending in {number}',
                paymentMethod: 'Payment method',
                country: {
                    label: 'Country',
                    placeholder: 'Select country',
                },
                address: {
                    label: 'Address',
                    placeholder: 'Enter address...',
                },
                city: {
                    label: 'City',
                    placeholder: 'Enter city...',
                },
                state: {
                    label: 'State',
                    placeholder: 'Enter state...',
                },
                postalCode: {
                    label: 'Postal Code',
                    placeholder: 'Enter postal code...',
                },
            },
            useThisPaymentMethod: 'Use this payment method',
        },
    },

    modal: {
        delete: {
            title: 'Delete? | Delete "{name}"?',
            description:
                'Are you sure you want to delete this? This action cannot be undone. | Are you sure you want to delete "{name}"? This action cannot be undone.',
        },

        default: {
            title: 'Mark as default',
            content: 'Are you sure you want to mark "{name}" as default?',
        },

        chartOfAccount: {
            title: 'Choose a tax chart of account',
            placeholder: 'Please select chart of account',
        },

        editBankAccount: {
            title: 'Edit Bank Account Details',
            placeholder: 'Please select chart of account',
            warning: {
                notAvailable:
                    "{name} is not a recognised bank with an available feed. Please be sure you entered the bank's name correctly. You will need to import transactions for unrecognised banks manually.",
                bankFeedsAvailable: 'Bank feeds are available',
            },
            yourBank: {
                label: 'Your Bank',
            },
            accountName: {
                label: 'Account Name',
                hint: 'As you would like it to appear in Cybooks (limited 150 characters)',
            },
            currency: {
                label: 'Currency',
            },
            iban: {
                label: 'IBAN',
            },
        },
        transaction: {
            bookClose: {
                title: 'Books closed prior {date}',
                description:
                    'This transaction’s date is prior or on your companies closing date. You’re not able to add or change any transactions prior the date.',
                button: 'I understand',
            },
        },
    },
    slideOutMenu: {
        save: {
            label: 'Save',
        },
        cancel: {
            label: 'Cancel',
        },
    },
    fileUpload: {
        uploadLabel: '{0} or drag and drop',
        clickToUploadLabel: 'Click to upload',
        canNotUpload: 'Can not upload',
        uploadFailedLabel: 'Upload failed, please try again',
    },

    grid: {
        noItem: 'No items',
        pagination: {
            prev: 'Previous',
            next: 'Next',
            page: 'Page',
            of: 'of',
        },
    },

    richText: {
        placeholder: 'Write something…',
        toolbar: {
            redo: 'Redo',
            undo: 'Undo',
            code: 'Code',
            codeBlock: 'Code Block',
            alignJustify: 'Align Justify',
            alignRight: 'Align Right',
            alignCenter: 'Align Center',
            alignLeft: 'Align Left',
            strike: 'strike',
            underline: 'Underline',
            italic: 'Italic',
            bold: 'Bold',
            blockquote: 'Blockquote',
            heading: 'Heading',
            paragraph: 'Paragraph',
            headingNumber: 'Heading {number}',
        },
    },

    salutation: {
        mr: 'Mr.',
        mrs: 'Mrs.',
        miss: 'Miss',
        ms: 'Ms.',
        dr: 'Dr.',
        prof: 'Prof.',
    },

    language: {
        en: 'English',
        de: 'German',
        el: 'Greek',
    },

    user: {
        user: 'User',
        salutation: 'Salutation',
        firstName: 'First name',
        lastName: 'Last name',
        abbreviation: 'Abbreviation',
        email: 'Email',
        admin: 'Admin',
        auditor: 'Auditor',
    },

    userMenu: {
        myAccount: 'My Account',
        logout: 'Log out',
        assistanceText: 'Need Assistance?',
        questionText: 'Have questions? Ask away!',
        sendEmailText: 'Send an email!',
        findAccountantText: 'Find an accountant',
        newsText: "What's new?",
        featureText: 'View the latest features and enhancements',
    },

    table: {
        searchByName: 'Search by name',
    },

    searches: {
        emptyState: 'No results found',
        moreCharacters: 'Enter more characters to search',
    },

    heading: {
        yourTrialExpired: (item) => {
            const discount = item.named('discount')
            const d = item.named('d')
            const h = item.named('h')
            const m = item.named('m')
            const s = item.named('s')
            const data = []

            if (d > 0) {
                data.push(`${d}d`)
            }

            if (h > 0 || data.length === 1) {
                data.push(`${h < 10 ? `0${h}` : h}h`)
            }

            if (m > 0 || data.length === 2) {
                data.push(`${m < 10 ? `0${m}` : m}m`)
            }

            if (s > 0 || data.length === 3) {
                data.push(`${s < 10 ? `0${s}` : s}s`)
            }

            if (data.length === 0) {
                if (discount) {
                    return `<b>Your discount has been applied!</b> You'll get ${
                        discount.percent
                    }% off a Standard or Premium plan for ${
                        discount.duration_in_months
                    } months.${
                        discount.expires_at
                            ? ` This offer expires on ${humanLongFormat(
                                  discount.expires_at
                              )}`
                            : ''
                    }`
                }

                return 'Your free trial expired'
            }

            return `Your free trial ends in ${data.join(' ')}`
        },
        buyAPlan: 'Buy a plan',
    },

    payment: {
        lineItems: {
            lineItemLabel: 'Line item',
            lineItemNamePlaceholder: 'Enter product name',
            accountLabel: 'Account',
            quantityLabel: 'Quantity',
            quantityPlaceholder: '0,00',
            priceLabel: 'Single price (Net)',
            pricePlaceholder: '{iso} 0,00',
            vatLabel: 'VAT',
            vatPlaceholder: 'Choose VAT',
            discountLabel: 'Discount',
            discountPlaceholder: '0',
            amountLabel: 'Amount (Net)',
            addLineItem: 'Add line item',
            addTotalDiscount: 'Add total discount',
        },
        lineItemsTypes: {
            discount: 'Discount',
            surcharge: 'Surcharge',
        },
    },

    topBand: {
        accountantDashboard: 'Accountant Dashboard',
        searchPlaceholder: 'Search in {chartOfAccount} (/)',
        addTooltip: 'Quick Create',
        notificationTooltip: 'Notifications',
        settingsTooltip: 'Settings',
        assistantTooltip: 'Assistant',

        addMenu: {
            general: {
                label: 'General',
                users: 'Add Users',
            },
            sales: {
                label: 'Sales',
                contacts: 'Contacts',
                estimates: 'Estimates',
                invoices: 'Invoices',
                creditNotes: 'Credit Notes',
            },
            expenses: {
                label: 'Expenses',
                expenses: 'Expenses',
                bills: 'Bills',
                reviewExpenses: 'Review Expenses',
            },
            banking: {
                label: 'Banking',
                connectAccount: 'Connect account',
                reviewTransactions: 'Review transactions',
                reconcile: 'Reconcile',
            },
        },
    },

    creditCard: {
        updated: 'Updated',
        bankBalance: 'Bank balance',
        inCyBooks: 'In cyBooks',
    },

    hint: {
        canNotBackSingleView: 'More than one vat rule in the items',
    },

    messages: {
        transactionsImported: 'Imported {count} transactions into {name}',
        internalServerError: 'Internal Server Error',
    },

    filters: {
        from: 'From',
        to: 'To',
        startDate: 'Start date',
        endDate: 'End date',
        asOfDate: 'As of {date}',
    },

    transaction: {
        bookPaymentSuccess: 'Payment booked successfully',
        bookPaymentModal: {
            invoiceNumber: 'Invoice number',
            customer: 'Customer',
            dateOfInvoice: 'Date of invoice',
            unpaidAmount: 'Amount / Unpaid amount',
            filterPanel: {
                name: 'Name',
                reference: 'Reference',
                dateOfTransfer: 'Date of transfer',
                amount: 'Amount',
            },
            description:
                'Record a payment you’ve already received, such as cash, cheque, or bank payment.',
            differenceReasonLabel: 'Reason for difference of {difference}',
            paymentDateLabel: 'Payment date',
            amountLabel: 'Amount',
            amountPlaceholder: 'Amount',
            paymentMethodLabel: 'Payment method',
            paymentAccountLabel: 'Payment account',
            paymentAccountPlaceholder: 'Select payment account',
            notesLabel: 'Memo / notes',
            bookBtn: 'Book',
            reasonOfDifference: {
                cashDiscount: 'Cash discount',
                partialPayment: 'Partial payment',
                transactionCost: 'Cost of payment transaction',
                other: 'Other',
            },
        },
        bookPaymentGrid: {
            columnName: 'Name',
            columnReference: 'Reference',
            columnDate: 'Date of transfer',
            columnAmount: 'Amount',
        },
    },
    shareLink: {
        title: 'Get share link',
        description: 'Your customer can view the {type} at this link:',
        copyLinkText: 'Copy the link and share it with your customer.',
        previewText: 'Preview in a new window',
        copyOnly: 'Copy only',
        copyAndMarkAsSent: 'Copy, and mark {type} as sent',
        linkCopiedMsg: 'Link copied to clipboard',
        failedToCopyMsg: 'Failed to copy',
        recipientText: '{no} from {name}',
        infoText: '{amount}, due on {date}',
        preview: {
            title: 'You are previewing how your customer will see the web version of your invoice.',
            backBtn: 'Go back to Cybooks',
        },
    },

    metricBalanceCard: {
        current: 'Current',
        overdue: 'Overdue',
    },

    clientsSwitching: {
        loading: 'Loading...',
        switchingTo: 'Switching to {name}',
    },

    chat: {
        chatFeed: {
            title: 'Messages',
            description: 'A list of recent messages',
            sendMessage: 'Send a message',
        },

        chatWindow: {
            title: 'Chat with {name}',
            you: 'You',
            today: 'Today',
            messageInputPlaceholder: 'Message',
            time: ({ named }) => {
                const { day } = named('diffDate')
                const createdAt = named('createdAt')

                if (day < 7) {
                    return dayjsFormat(createdAt, 'dddd h:mma')
                }

                return dayjsFormat(createdAt, 'MMM D YYYY, h:mma')
            },
            day: ({ named }) => {
                const createdAt = named('createdAt')
                return dayjsFormat(createdAt, 'MMM D YYYY')
            },
        },
    },

    dateDropdown: {
        optionToday: 'Today',
        optionThisWeek: 'This Week',
        optionThisMonth: 'This Month',
        optionThisQuarter: 'This Quarter',
        optionThisYear: 'This Year',
        optionYesterday: 'Yesterday',
        optionPrevWeek: 'Previous Week',
        optionPrevMonth: 'Previous Month',
        optionPrevQuarter: 'Previous Quarter',
        optionPrevYear: 'Previous Year',
        optionCustom: 'Custom',
    },

    journalHistory: {
        title: 'Journal history',
        account: 'Account',
    },

    noPaymentAccountModal: {
        title: 'No payment account',
        alertTitle: 'Please create a payment account',
        alertDescription:
            'To be able to create an expense you need to setup a payment account.',
        createPaymentAccount: 'Create payment account',
    },

    documentExtractionStatus: ({ named }) => {
        const status = named('status')

        if (status === DOCUMENT_STATUS.NEW) {
            return 'New'
        }
        if (status === DOCUMENT_STATUS.VIEWED) {
            return 'Viewed'
        }
        if (status === DOCUMENT_STATUS.READY) {
            return 'Ready'
        }

        return ''
    },

    billStatus: ({ named }) => {
        const status = named('status')

        if (status === BILL_STATUS.DRAFT) {
            return 'Draft'
        }
        if (status === BILL_STATUS.OPEN) {
            return 'Open'
        }
        if (status === BILL_STATUS.DUE) {
            return 'Overdue'
        }
        if (status === BILL_STATUS.PAID) {
            return 'Paid'
        }
        if (status === BILL_STATUS.PART_PAID) {
            return 'Partially paid'
        }

        return ''
    },

    expenseStatus: ({ named }) => {
        const status = named('status')

        if (status === EXPENSE_STATUS.UN_BILLED) {
            return 'Unbilled'
        }

        if (status === EXPENSE_STATUS.NON_BILLABLE) {
            return 'Non Billable'
        }

        if (status === EXPENSE_STATUS.INVOICED) {
            return 'Invoiced'
        }

        return null
    },

    longTimeAgo: ({ named }) => {
        const { year, day, hour, minute, second } = named('diffDate')
        if (year > 0) {
            return `${year} years ago`
        }

        if (day > 0) {
            return `${day} days ago`
        }

        if (hour > 0) {
            return `${hour} hours ago`
        }

        if (minute > 0) {
            return `${minute} minutes ago`
        }

        if (second > 10) {
            return `${second} seconds ago`
        }

        return 'Just now'
    },

    logTime: ({ named }) => {
        const { year, month, day, hour, minute, second } = named('diffDate')
        const created = named('createdAt')

        if (year > 0 || month > 0 || day > 0) {
            return dayjsFormatWithTimezone(created, 'DD. MMM YYYY, HH:mm')
        }

        if (hour > 0) {
            return `${hour} hours ago`
        }

        if (minute > 0) {
            return `${minute} minutes ago`
        }

        if (second > 10) {
            return `${second} seconds ago`
        }

        return 'Just now'
    },

    timeAgo: ({ named }) => {
        const { year, day, hour, minute, second } = named('diffDate')
        if (year > 0) {
            return `${year}y ago`
        }

        if (day > 0) {
            return `${day}d ago`
        }

        if (hour > 0) {
            return `${hour}h ago`
        }

        if (minute > 0) {
            return `${minute}m ago`
        }

        if (second > 10) {
            return `${second}s ago`
        }

        return 'Just now'
    },
}
