export default {
    home: {
        header: {
            heading: 'Hello, {user}',
            description: '{company}',
        },
        tab: {
            dashboard: 'Dashboard',
            gettingStarted: 'Getting Started',
            recentUpdates: 'Recent Updates',
            announcements: 'Announcements',
        },
    },

    newDashboard: {
        title: 'Dashboard',
        recentUpdates: 'Recent updates',
        welcomeCard: {
            title: 'Welcome to Cybooks',
            description:
                'My name is Christopher and I am your contact at Cybooks.',
            helpBtn: 'I need help',
        },
        connectBankCard: {
            title: 'Bank account',
            description:
                'For a real time overview about your financial, you should connect your bank account',
            connectBtn: 'Connect bank account',
            reconciled: 'Reconciled',
            reconcile: 'Reconcile',
            bankBalance: 'Bank balance',
            inCybooks: 'In Cybooks',
            transactionTooltip:
                'no unreconciled transactions|1 unreconciled transaction|{count} unreconciled transactions',
        },
        inviteAccountantCard: {
            title: 'Invite accountant',
        },
        vatReportCard: {
            title: 'VAT report',
        },
    },

    dashboard: {
        title: 'Banking Dashboard',

        accounts: {
            inCyBooks: 'In Cybooks',
            bankBalance: 'Bank balance',
        },

        metricItems: {
            openInvoices: '{count} open invoices',
            overdueInvoices: '{count} overdue invoices',
            draftInvoices: '{count} draft invoices',
            showOpenInvoices: 'Show open invoices',
            showOverdueInvoices: 'Show overdue invoices',
            showDraftInvoices: 'Show draft invoices',
            createAnInvoice: 'Create an invoice',
        },

        paymentAccounts: {
            balanceInCybooks: 'Balance in Cybooks',
            statementBalance: 'Statement balance ({date})',
            reconciled: 'Reconciled',
            noTransactionImported: 'No transaction imported',
            importBankStatement: 'Import bank statement',
            reconcileBtn: 'Reconcile 1 item | Reconcile {count} items',
        },

        cashflow: {
            title: 'Cashflow',
            amount: 'Amount',
            cashAsOn: 'Cash as on {date}',
            incoming: 'Incoming',
            outgoing: 'Outgoing',
            collectingDataText: 'Collecting data ...',
            options: {
                thisFiscalYear: 'This fiscal year',
                lastFiscalYear: 'Last fiscal year',
                last12Months: 'Last 12 Months',
            },
            connectBankBtn: 'Connect bank account',
            connectBankDescription:
                'To see your cashflow, connect your bank account.',
            reconcileBtn: 'Reconcile now',
            reconcileDescription:
                'To see your cashflow, reconcile your transactions.',
        },

        balanceReceivables: {
            title: 'Total Receivables',
            tooltip:
                'Current and overdue amount that you’re yet to recieve from customers.',
            barText: 'Total Unpaid Invoices: {value}',
            menu: {
                createInvoice: 'Create invoice',
            },
        },

        balancePayables: {
            title: 'Total Payables',
            tooltip:
                'Current and overdue amount that you’re yet to pay to your vendors.',
            barText: 'Total Unpaid Bills: {value}',
            menu: {
                createBill: 'Create bill',
            },
        },

        salesCard: {
            title: 'Sales',
            loadingText: 'Calculating sales ...',
            noResultText:
                'No invoices have been concluded for your selected period.',
            createBtn: 'Create invoice',
            totalSalesText: 'Total Sales',
            dateOptions: {
                last30Days: 'Last 30 days',
                thisMonth: 'This month',
                thisQuarter: 'This quarter',
                thisYear: 'This financial year',
                prevMonth: 'Last month',
                prevQuarter: 'Last quarter',
                lastYear: 'Last financial year',
            },
        },

        newExpenseCard: {
            title: 'Expenses',
            loadingText: 'Calculating expenses ...',
            noResultText:
                'No purchases have been concluded for your selected period.',
            createBtn: 'Create Expense',
            spendText: 'Spending for {period}',
            priorText: ' from prior days',
            reconcilePercentage: '{value}% reconciled',
            reconciledDescription:
                'Your expenses is based on spending categorised so far. Keep reconciling for a complete picture of your spending.',
        },

        expensesCard: {
            title: 'Top Expenses',
            dateOptions: {
                last30Days: 'Last 30 days',
                thisQuarter: 'This quarter',
                thisMonth: 'This month',
                prevFiscalYear: 'Previous Fiscal Year',
                prevQuarter: 'Last quarter',
                prevMonth: 'Last month',
                last6: 'Last 6 Months',
                last12: 'Last 12 Months',
                thisYear: 'This year',
                lastYear: 'Last year',
            },
            emptyState: {
                title: 'You have no expenses for this period',
                description: 'Go ahead an create your first expense or bill.',
                createExpense: 'Create Expense',
                createBill: 'Create Bill',
            },
        },

        incomeExpenses: {
            title: 'Income & Expenses',
            income: 'Income',
            expenses: 'Expenses',
            tooltip: 'Income vs Expenses for the last 12 months',
        },
    },

    gettingStarted: {
        title: 'Gettings started',
    },

    recentUpdates: {
        title: 'Recent updates',
        moreText: 'More Updates',
    },

    announcements: {
        title: 'Announcements',
    },
}
