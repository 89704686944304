export default {
    accountantSetting: {
        sidebar: 'Company Settings',
        setting: 'Settings',
        title: 'Company profile',
        description: 'Update your company photo and details here.',

        address: {
            title: 'Address',
            firstDescription:
                'This will be used as your default business address.',
            secondDescription: 'It will appear in the footer of the invoice.',
            companyNameLabel: 'Company name *',
            legalCompanyNameLabel: 'Legal company name',
            ceoDirectorLabel: 'CEO / Director',
            typeOfCompanyLabel: 'Type of company',
            address1Label: 'Address 1',
            address2Label: 'Address 2',
            postcodeLabel: 'Postcode',
            cityLabel: 'City',
            countryLabel: 'Country',
            industryLabel: 'Industry',
            numberOfCoWorkersLabel: 'Number of co-workers',
        },

        tax: {
            title: 'Tax- and register numbers',
            description:
                'For a proper business document, you must at least include your tax number.',
            districtCourtLabel: 'District court',
            companyRegistrationLabel: 'Company registration number',
            vatIdLabel: 'VAT ID',
            taxReferenceLabel: 'Tax reference*',
            taxRateLabel: 'Tax rate',
            cashBasisLabel: 'Cash basis - on payment date',
            accountingMethodLabel: 'Accounting method',
            methodOfCalculationLabel: 'Method of calculation Income STMT',
            priceLabel: 'Prices on documents ',
            netPricesLabel: 'Net-Prices',
            netPricesDescription: 'Prices on documents are without vat',
            grossPricesLabel: 'Gross-Prices',
            grossPricesDescription: 'Prices on documents include vat',
        },

        contact: {
            title: 'Contact information',
            description:
                'Be available for possible questions of your customers.',
            phoneLabel: 'Phone',
            faxLabel: 'FAX',
            emailLabel: 'Email',
            webLabel: 'Web domain',
        },

        payment: {
            title: 'Payment information',
            firstDescription:
                'Enter your bank information so that your bills can be paid.',
            secondDescription:
                'You want your PayPal address, directly on your invoice? Then add it here.',
            bankNameLabel: 'Bank name',
            accountNumberLabel: 'Account number',
            sortCodeLabel: 'Sort code',
            ibanLabel: 'IBAN',
            swiftCodeLabel: 'Swift Code',
        },

        logo: {
            title: 'Upload Logo',
            description:
                'Upload your personal logo in .png, .jpg or .pdf format.',
            uploadDescription: 'SVG, PNG, JPG or GIF (max. 800x400px)',
        },

        message: {
            updateSuccessfully: 'Update the profile of company successfully',
        },
    },

    accountantSubscriptionBilling: {
        sidebar: 'Subscriptions & Billing',
        title: 'Subscriptions and billing',
        tabs: {
            accountant: 'Accountant-billed subscriptions',
            clients: 'Client-billed subscriptions',
        },
        searchText: 'Search by client name or ID',
        columns: {
            contactPerson: 'Client / Contact',
            products: 'Products',
            price: 'Price',
            status: 'Status',
            canceledOn: 'Canceled on',
        },
        accountant: {
            description:
                'You receive billing for the clients listed under this tab. You can change their plan, activate products, and transfer billing to them from here.',
            priceMonth: '{price} / month',
            id: 'ID: {id}',
            subscribed: 'Subscribed',
            unsubscribed: 'Unsubscribed',
        },
        clients: {
            trialEnd: 'Trial ends {date}',
            tab: {
                activeClients: 'Active clients',
                cancelledClients: 'Cancelled clients',
            },
        },
    },

    accountantEmails: {
        sidebar: 'Email Accounts',
        title: 'Email Accounts',
        connectBtn: 'Connect a mailbox',
        disconnectSuccess: 'Mailbox disconnected successfully',
        mailOwner: '{name} <{email}>',
        disconnectBtn: 'Disconnect',
        gridColumns: {
            mailbox: 'Mailbox',
            owner: 'Owner',
            status: 'Status',
        },
        connectEmailModal: {
            title: 'Connect a mailbox',
            options: {
                gmail: {
                    title: 'Gmail',
                    description: 'Connect a Google Gmail account',
                },
                outlook: {
                    title: 'Microsoft 365',
                    description: 'Connect a Office 365 account',
                },
            },
        },
        disconnectModal: {
            title: 'Disconnect {name}?',
            description:
                'This will remove the mailbox from the system. Are you sure you want to disconnect this mailbox?',
        },
    },
}
