export default {
    accountant: {
        sidebar: {
            yourPractise: 'Your practise',
            dashboard: 'Dashboard',
            clients: 'Clients',
            projects: 'Projects',
            inbox: 'Inbox',
            setting: 'Setting',
            team: 'Team',
            emails: 'Email Accounts',
        },
        roles: {
            tenant_accountant_member: 'Member',
        },
    },
}
