export default {
    accountantProjects: {
        projects: 'Projects',
        createProject: 'Create Project',
        newProject: 'New project',
        noProjectFound: 'No projects found',
        createNewProject: 'Create new project',
        goAHeadCreateProject: 'Go ahead and create your first project.',
        projectName: 'Project Name *',
        name: 'Name',
        client: 'Client',
        clientWillNotBeSent: 'The client will not be sent anything.',
        clients: 'Clients',
        startDate: 'Start Date',
        reminders: 'Reminders',
        sendReminders: 'Send reminders',
        dateOfFirstReminder: 'Date of first reminder',
        dueDate: 'Due Date',
        recurring: 'Recurring',
        subject: 'Subject',
        automaticallyRecreate: 'Automatically recreate this work in the future',
        accountingPeriod: 'Accounting Period',
        selectThePeriodOfTime: 'Select the period of time this work is for',
        assignees: 'Assignees',
        automaticallyAddMe: 'Automatically add {name} to unassigned tasks.',
        internalDueDate: 'Internal Due Date',
        dueDateExtension: 'Due Date Extension',
        reviewer: 'Reviewer',
        willGetANotification:
            'Will get a notification once the project is completed for review.',
        billingRate: 'Billing Rate',
        nonBillableHours: 'Use 0 for non-billable hours.',
        isoPerHour: '{iso} Per hour',
        serviceItem: 'Service Item',
        selectAService:
            'Select a service to automatically populate the billing rate.',
        clientContact: 'Client / Contact',
        project: 'Project',
        teamChat: 'Team Chat',
        clientTasks: 'Client Tasks',
        assignee: 'Assignee',
        createSuccess: 'Project "{name}" created successfully',
        deleteSuccess: 'Project "{name}" deleted successfully',
        updateSuccess: 'Project "{name}" updated successfully',

        recurrence: {
            everyDayExcludingWeekend: 'Repeat every day (excluding weekends)',
            everyDayIncludingWeekend: 'Repeat every day (including weekends)',
            everyWeek: 'Repeat every week',
            every2Week: 'Repeat every 2 weeks',
            everyMonth: 'Repeat every month',
            everyMonthLastDay: 'Repeat every month (last day of month)',
            every3Month: 'Repeat every 3 months',
            every6Month: 'Repeat every 6 months',
            every12Month: 'Repeat every 12 months',
        },

        nameWithMe: ({ named }) => {
            const isMe = named('is_me')
            const name = named('name')
            if (isMe) {
                return `${name} (me)`
            }

            return name
        },

        startOnDueOn: ({ named }) => {
            const startDate = named('startDate')
            const dueDate = named('dueDate')

            const textData = []
            if (startDate) {
                textData.push(`Starts on ${startDate}`)
            }

            if (dueDate) {
                textData.push(`Due on ${dueDate}`)
            }

            return textData.join(' - ')
        },
        tabs: {
            list: 'List',
            clientTasks: 'Client Tasks',
            teamChat: 'Team Chat',
            files: 'Files',
        },
        status: 'Status',
        todo: 'Todo',
        inProgress: 'In Progress',
        inReview: 'In Review',
        done: 'Done',
        searchByTitleOrDescription: 'Search by title or description',
        tasks: 'Tasks',
        noClientTasks: 'No client tasks',
        goAHeadCreateTask: 'Go ahead and create your first client task',
        createClientTask: 'Create client task',
        whatNeedToBeDone: 'What needs to be done?',
        createTask: 'Create task',
        assigner: 'Assigner',
        subTasks: 'Sub Tasks',
        percentDone: '{percent}% Done',
        comments: 'Comments',
        addAComment: 'Add a comment...',
        typeToMentionSomebody: "Type {'@'} to mention somebody",
        viewRemainingComments: 'View {count} remaining older comments',
        newChat: 'New Chat',
        createChat: 'Create Chat',
        selectAChat: 'Select a chat',
        clickOnAChatToRead: 'Click on a chat to read or write messages',
        startAChat: 'Start a chat',
        addTask: 'Add Task',
        showClientTasks: 'Show client tasks',
        assignToUseTasks: 'Assign to a client to able to use client tasks',
        assignToUseFiles: 'Assign to a client to able to use files',
    },
}
