export default {
    accountantClients: {
        nameClient: "{name}'s client",
        tabs: {
            overview: 'Overview',
        },

        clientDetailTabs: {
            dashboard: 'Dashboard',
            emails: 'Emails',
            activity: 'Activity',
            notes: 'Notes',
            documents: 'Documents',
            clientVault: 'Client Vault',
            transactions: 'Transactions',
            taskReport: 'Client Tasks Report',
        },

        emails: {
            noEmailConnectionTitle: 'No Email connected',
            noEmailConnectionDescription:
                'Connect your mail account to be able to see your client’s email.',
            connectEmailBtn: 'Connect mail account',
            noEmailTitle: 'No Emails yet',
            noEmailDescription: 'Start writing a new mail to your client',
            writeEmailBtn: 'Write email',
        },

        messages: {
            clientSuccessCreated: 'Client "{email}" was successfully created!!',
        },

        overview: {
            title: 'Clients',
            description: 'List of the clients you’re connected to.',
            addClient: 'Add client',
            numberClients: 'no clients | 1 client | {count} clients',
            creatingClient: 'We’re creating your client.',
            youCantLogin:
                'You can’t login. This client has no active subscription',
            loginToClient: 'Login to client',
            searchByCompanyName: 'Search by company name',
        },

        addClient: {
            title: 'Add a client',
            createClient: 'Create Client',
            businessName: {
                label: 'Business name *',
                placeholder: 'Enter business name...',
            },
            email: {
                label: 'Email *',
                placeholder: 'Enter email...',
                hint: "Use the company owner's address. When you buy a subscription, we send an email to let them know. We'll also send other account info now and then.",
            },
            displayNameAs: {
                label: 'Display name as',
                placeholder: 'Enter display name...',
                hint: 'This will appear in your client dashboard.',
            },
            mobile: {
                label: 'Mobile',
                placeholder: 'Enter mobile...',
            },
            clientFirstName: {
                label: 'Clients first name',
                placeholder: 'Enter clients first name...',
            },
            clientLastName: {
                label: 'Clients last name',
                placeholder: 'Enter clients last name...',
            },
            phone: {
                label: 'Phone',
                placeholder: 'Enter phone...',
            },
            website: {
                label: 'Website',
                placeholder: 'Enter website...',
            },
            address: {
                label: 'Address',
                placeholder: 'Enter address...',
            },
            postcode: {
                label: 'Postcode',
                placeholder: 'Enter postcode...',
            },
            city: {
                label: 'City',
                placeholder: 'Enter city...',
            },
            country: {
                label: 'Country',
                placeholder: 'Select country...',
            },

            products: {
                title: 'Products',
                subscriptionOptions: {
                    subscription: 'Subscription',
                    noSubscription: 'No subscription',
                },
                savePaymentMethodTerm:
                    'By selecting Save and use, I understand that I am enrolling in a Cybooks subscription program. I authorise Cybooks, using electronic signature, to charge my payment method for the subscription(s) selected, unless I cancel. To cancel, sign in and go to Billing Page. If you are receiving discounted pricing, you will automatically be charged the full price when the discount period ends.',
                billingOptions: {
                    title: 'Billing options',
                    wholesaleAccount: {
                        label: 'Wholesale Account',
                        hint: 'We bill your firm',
                    },
                    directAccount: {
                        label: 'Direct Account',
                        hint: 'We bill your client',
                    },
                    perMonth: 'per month',
                    total: 'Total',
                    applicableTaxesAndFee: '+ applicable taxes and fees',
                    makeMeThePrimaryAdmin: {
                        label: "Make me the primary admin. I certify that I am acting on behalf of my client and with my client's consent.",
                        hint: "We'll email your client to let them know that you're the Primary Admin for this QuickBooks company.",
                    },
                },
                noSubscription: {
                    title: 'No subscription? No problem.',
                    description:
                        "Let's put this client into your client list now. You can always add a Cybooks subscription later.",
                },
            },
        },

        editClient: {
            title: 'Edit client',
            success: 'Client updated successfully',
            subscription: 'Cybooks subscription',
            subscriptionAlert: {
                title: 'Want to make changes to this account?',
                description: 'Go to Subscription and billing',
            },
        },

        detail: {
            editBtn: 'Edit client',
            suggestFeatureBtn: 'Make suggestion',
            moreFeaturesTitle: 'More Features coming soon ...',
            moreFeatureDescription:
                'We’re working hard to add new features to help you manage your clients. Meanwhile you’re able to edit your clients details if needed.<br /><br />If you have any suggestions that makes your live as an accountant easier, <span class="font-semibold">please let us know.</span>',
        },

        columns: {
            contactPerson: 'Client / Contact',
            emailPhoneNumber: 'Email / Phone',
            banking: 'Banking',
            lead: 'Lead',
        },

        projects: 'Projects',

        notes: {
            title: 'Notes',
            addNote: 'Add note',
            descriptionText: 'Last edited {date} by {author}',
            deleteSuccessMsg: 'Note deleted successfully',
            pinSuccessMsg: ({ named }) => {
                return `${named('title') ?? 'Note'} is pinned to top`
            },
            unpinSuccessMsg: ({ named }) => {
                return `${named('title') ?? 'Note'} is unpinned`
            },
            list: {
                actions: {
                    pin: 'Pin to top',
                    unpin: 'Unpin note',
                    edit: 'Edit note',
                    delete: 'Delete note',
                },
                emptyCard: {
                    noResults: 'No Notes found',
                    noResultsDescription: ({ named }) => {
                        if (named('title')) {
                            return `Your search “${named(
                                'title'
                            )}” did not match any Notes. Please try again.`
                        }

                        return 'No Notes found.'
                    },
                    clearSearchBtn: 'Clear search',
                    createNoteBtn: 'Create Note',
                },
            },
            createModal: {
                title: 'Add note',
                savingText: 'Saving...',
                successMessage: 'Note created successfully',
                fields: {
                    title: {
                        label: 'Title',
                        placeholder: 'Enter title...',
                    },
                },
            },
            editModal: {
                title: 'Edit note',
                successMessage: 'Note updated successfully',
            },
        },

        clientVault: {
            title: 'Client Vault',
            addBtn: 'Add encrypted record',
            deleteSuccessMsg: 'Record deleted successfully',
            list: {
                emptyCard: {
                    title: 'No records found',
                    description:
                        'Here, you can store sensitive information such as usernames and passwords. We automatically encrypt your data to provide an additional layer of security. Use the button below to get started.',
                    addBtn: 'Add encrypted record',
                },
            },
            createModal: {
                title: 'New Encrypted Record',
                savingText: 'Saving...',
                successMessage: 'Record created successfully',
                fields: {
                    name: {
                        label: 'Name *',
                        placeholder: 'e.g app',
                    },
                    username: {
                        label: 'Username',
                        placeholder: "e.g john{'@'}doe.com",
                    },
                    password: {
                        label: 'Password',
                        placeholder: 'e.g myPassword',
                    },
                    link: {
                        label: 'Link (URL)',
                        placeholder: 'e.g myapp.com/login',
                    },
                },
            },
            editModal: {
                title: 'Edit record',
                successMessage: 'Record updated successfully',
            },
        },

        documents: {
            searchText: 'Search by title or description',
            addNewFolder: 'Add new folder',
            uploadFile: 'Upload file',
            fileName: 'File name',
            contains: 'Contains',
            dateUploaded: 'Date uploaded',
            lastUpdated: 'Last updated',
            uploadedBy: 'Uploaded by',
            containsDocument: '{docs} docs, {folders} folders',

            uploadModal: {
                title: 'Uploader document',
                description: 'SVG, PNG, JPG or GIF (max. 800x400px)',
                fileRestriction: 'File upload restrictions',
                maxFileSize: 'Max. file size is {size} MB',
                allowFile: 'Allowed file types: {files}',
                fileNameContain:
                    'File name can contain (a-z, A-Z) and numbers (0-9)',
                fileNameMustContain:
                    'File name must contain at least one character',
                uploadError: 'An error occurred while uploading the file',
            },
        },

        tasksReport: {
            tasks: 'Tasks',
            status: 'Status',
            project: 'Project',
            dueDate: 'Due Date',
            nextReminder: 'Next Reminder',
            searchBySubject: 'Search by subject',
        },
    },
}
