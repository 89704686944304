export default {
    accountantTeam: {
        title: 'Team members',
        description:
            'Invite others to join your team and manage assigned clients',
        members: {
            columns: {
                name: 'Name',
                email: 'Email',
                role: 'Role',
                billingRate: 'Billing rate',
            },
            manageClients: 'Manage clients',
            removeMember: 'Remove member',
            deleteConfirmation:
                'The user "{name}" will be permanently deleted.',
            removeMemberSuccessMsg: '{name} has been removed.',
        },
        pendingInvitations: {
            title: 'Pending invitations',
            columns: {
                email: 'EMAIL',
                role: 'Role',
                sentDate: 'Sent Date',
            },
        },
        inviteModal: {
            title: 'Invite New Team Member',
            emailLabel: 'Email',
            emailPlaceholder:
                'An invitation will be sent to this email address.',
            sendInviteBtn: 'Send invite',
            sendSuccessMsg: 'Invitation has been sent.',
        },
        clientsModal: {
            title: "Manage {name}'s Clients",
            description: 'Select clients to assign to this member.',
        },
    },
}
