export default {
    accountantInbox: {
        title: 'Inbox',
        yourInbox: 'Your inbox',
        all: 'All',
        chat: 'Chat',
        myAccountant: 'My Accountant',
        detail: 'Details',
        newConversation: 'New Conversation',
        attributes: 'Attributes',
        assignee: 'Assignee',
        clientData: 'Client Data',
        email: 'Email',
        company: 'Company',
        phone: 'Phone',
        id: 'ID',
        from: 'From',
        to: 'To',
        subject: 'Subject',
        noClientFound: 'No Client found',
        setupMail: 'Setup',
        createNewModal: {
            title: 'Create New',
            conversation: 'Conversation',
            requestToClient: 'Request to Client',
        },
        conversationType: {
            chat: 'Chat',
            email: 'Email',
        },
        error: {
            noMailConnection: 'Please setup a mail connection first.',
        },
    },
}
