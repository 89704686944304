export default {
    auth: {
        general: {
            passwordHint: 'Must be at least 8 characters.',
            creatingCompanyLabel: 'Hang on, your new company will be created',
            setupText:
                'We’ll now setup a new account for you. This may take a few seconds.',
        },
        form: {
            firstNameLabel: 'First Name *',
            firstNamePlaceholder: 'Enter your first name',
            lastNameLabel: 'Last Name *',
            lastNamePlaceholder: 'Enter your last name',
            emailLabel: 'Email *',
            emailPlaceholder: 'Enter your email',
            passwordLabel: 'Password *',
            passwordPlaceholder: 'Enter your password',
            passwordConfirmationLabel: 'Re-enter Password *',
            passwordConfirmationPlaceholder: 'Enter your password',
            passwordHint: 'Must be at least 8 characters.',
            companyNameLabel: 'Company name *',
            companyNamePlaceholder: 'Enter your company name',
            companyLabel: 'Company',
            countryLabel: 'Country *',
            countryPlaceholder: 'Select your country',
            phoneLabel: 'Phone *',
            phonePlaceholder: 'Enter your phone number',
            accountingSoftwareLabel: 'Accounting Software *',
        },
        register: {
            title: 'Register',
            leftSection: {
                title: 'Digitize your business.',
                feature1: 'We improve daily with your feedback',
                feature2: 'Your data is safe and secure',
                feature3: 'Get access to live customer support',
                reviewsText: 'from 20+ reviews',
            },
            tryFreeLabel: 'Try Cybooks Free',
            tryFreeDescription: 'No credit card is required. Cancel anytime.',
            createAnAccountLabel: 'Create an account',
            startYourFreeTrialLabel: 'Start your 14-day free trial.',
            scheduleDemoLabel: 'Schedule a demo',
            scheduleDemoLDescription:
                'See how Cybooks can improve your workflow.',
            nextStepButton: 'Next step',
            tryFreeBtn: 'Try it Free',
            alreadyHaveAnAccountLabel: 'Already have an account?',
            loginButton: 'Log in',
            passwordPlaceholder: 'Password (min 8 characters)',
            firstNamePlaceholder: 'First Name',
            lastNamePlaceholder: 'Last Name',
            emailPlaceholder: 'Email',
            termsLabel:
                'I confirm that I have read and agree to Cybooks <a class="underline" target="_blank" href="https://www.cybooks.com.cy/policies/terms-of-service">Terms of Service</a> and <a class="underline" target="_blank" href="https://www.cybooks.com.cy/policies/privacy">Privacy Policy</a>.',
            advertTermLabel:
                'I hereby authorise Cybooks to store the contact details I have provided and to contact me about product innovations and promotions for advertising purposes.',
            demoAlertTitle: 'Schedule a demo',
            demoAlertDescription:
                'We’re currently working together with selected accountants to improve Cybooks for accountants.\n\n' +
                'If you’re interested as an accountant to try out Cybooks, you can schedule a demo with us.',
        },
        login: {
            title: 'Login',
            loginInToYourAccountLabel: 'Log in to your account',
            welcomeBackLabel: 'Welcome back! Please enter your details.',
            forgotPasswordButton: 'Forgot password',
            signInButton: 'Sign in',
            dontHaveAccountLabel: 'Don’t have an account?',
            wrongAccountLabel: 'Wrong account?',
            signUpButton: 'Sign up',
        },
        loginVerify: {
            title: 'Verify OTP',
            loginInToYourAccountLabel: 'Please enter 2FA code',
            welcomeBackLabel:
                'Two-factor authentication (2FA) is enabled for your account. Please enter a code to log in.',
            signInButton: 'Sign in',
            wrongAccountLabel: 'Wrong account?',
            form: {
                otpLabel: 'Two-factor authentication code *',
                otpPlaceholder: '123456',
                verifyBtn: 'Verify',
            },
        },
        verifyCode: {
            title: 'Check your email',
            description: 'We sent a verification link to {email}',
            verifyEmailButton: 'Verify email and create company',
            didntReceiveTheEmailLabel: 'Didn’t receive the email?',
            clickToResendEmailLabel: 'Click to resend',
            backToLoginLabel: 'Back to login',
        },
        createTenant: {
            title: 'Create company',
            heading: 'What’s the name of your company or team?',
            description: 'This will be the name of your application space.',
            createAccountButton: 'Create account',
            alreadyHaveAnAccountLabel: 'Already have an account?',
            loginButton: 'Log in',
        },
        forgotPassword: {
            forgotPasswordLabel: 'Forgot password?',
            noWorriesLabel: 'No worries, we’ll send you reset instructions.',
            forgotPasswordButton: 'Reset password',
            backToLoginButton: 'Back to login',
        },
        checkYourEmail: {
            checkYourEmailLabel: 'Check your email',
            emailMessageLabel:
                'If the email exists within our system, we have sent a password reset link to {email}',
            didntReceiveEmail: 'Didn’t receive the email?',
            clickToResend: 'Click to resend',
        },
        setNewPassword: {
            setNewPasswordLabel: 'Set new password',
            setNewPasswordMessage:
                'Your new password must be different to previously used passwords.',
        },
        passwordResetDone: {
            passwordResetDoneLabel: 'Password reset',
            passwordResetDoneMessage:
                'Your password has been successfully reset. Click below to log in magically.',
        },
        invite: {
            title: 'Invitation',
            loginButton: 'Login',
            loginAndContinueButton: 'Login & Continue',
            failed: {
                heading: 'Invitation link is expired',
                firstDescription:
                    "Hi, there your invitation link has expired, because you haven't used it.",
                secondDescription:
                    'Invitation link expires in every 24 hours and can only be used once.',
            },
            success: {
                firstDescription:
                    'You’re logged with account <br/><b>{email}</b>',
                secondDescription:
                    'Do you want to logout and continue with the invitation?',
            },
            login: {
                description:
                    'Please login to continue with<br/> the invitation for email<br/> <b>{email}</b>',
            },
            register: {
                signUpButton: 'Sign up',
                description:
                    'Please sign up to continue with<br/> the invitation for email<br/> <b>{email}</b>',
            },
        },
        accountantRegister: {
            leftSection: {
                title: 'Practice management for accountants',
                description: 'Free as a limited time offer for accountants',
                feature1: 'Manage all your clients',
                feature2: "Coordinate all your firm's tasks",
                feature3: 'Create and send automatically reminders to clients',
                feature4: 'Identify inconsistency of client transactions',
                feature5: 'Do the accounting for your clients',
            },
            rightSection: {
                loginCredentials: 'Your Login Credentials',
                contact: 'Contact person at your accounting firm',
                dataNote:
                    'We take the issue of data protection very seriously and only ask for the necessary information. This data allows us to personalise our advice and your experience with our portal.',
                registerBtn: 'Register for free',
            },
        },
        accountantWelcome: {
            title: 'A warm welcome!',
            description:
                'You will receive an e-mail with a confirmation link. \n After confirmation, you can log in to the portal.',
            endText: 'We look forward to seeing you!',
        },
    },
}
